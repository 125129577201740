<template>
  <div id="app">
    <div>
      <div class="bg-img py-10">
        <h1 class="text-center font-weight-medium display-2 py-16">
          Privacy & Policy
        </h1>
      </div>

      <v-container class="pt-10">
        <div class="content padding">
          <p><strong>All personal information gathered and stored through this site shall be governed by the terms laid
              out by Zebu.</strong></p>
          <ul>
            <li>This website uses the contact information that allows you to invite your contacts(referral program)
              using google API</li>
            <li>This website captures personal information about the proposed user while registering with Zebu to
              opening an account for online trading and accounts access.</li>
            <li>The information will be used to promptly identify and attend to you personally based on your specific
              requests.</li>
            <li>On successful registration, you are eligible to log in and access the facilities on the site.</li>
          </ul>
          <p><strong><br>
              Disclosure of information</strong></p>
          <ul>
            <li>Zebu will keep your personal information confidential.</li>
            <li>Zebu shall not sell or rent the information to anyone.</li>
            <li>In the case of compulsion to share your information, we shall obtain your prior consent; the information
              shall be shared with only those people/companies who have been authorized by you.</li>
          </ul>
          <p><strong><br>
              Security</strong><br>
            Web policy mandates the use of a password. Hence, your account information is safe and protected.</p>
          <p><strong>Amendment rights</strong><br>
            Zebu reserves the right to change the contents of the privacy policy from time to time. Any predominant
            change in the manner of use of personal information shall be notified on this site. We welcome your
            suggestions and queries on the same.</p>
        </div>
      </v-container>
    </div>
  </div>
</template>

<style>
.content {
  color: #677788;
}
</style>    

<script>

export default {

}
</script>