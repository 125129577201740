<template>
  <div id="app">
    <div>
      <div class="bg-img py-10">
        <h1 class="text-center font-weight-medium display-2 py-16">
          Terms & Conditions
        </h1>
      </div>

      <v-container>
        <v-card outlined class="hidden-md-and-up pa-2">
          <span class="menubtn">Menu</span>
          <v-btn icon plain color="blue" class="hidden-md-and-up togglemenu-icon float-right pb-2 mt-0"
            @click="toggleMenu = !toggleMenu">
            <v-icon v-if="toggleMenu">mdi-menu</v-icon>
            <v-icon :ripple="false" v-else color="black" size="18">mdi-close</v-icon>
          </v-btn>
        </v-card>
        <p v-if="toggleMenu"></p>
        <v-card enable-resize-watcher elevation="0" class="position-fixed grpitem  d-lg-none d-xl-flex overflow-y-auto"
          v-else v-model="selectedItem" :absolute="toggleMenu" :attach="'#toolbar'" :content-class="'px-2 elevation-0'"
          :nudge-top="$vuetify.breakpoint.smAndDown ? -52 : -12" min-width="100%">

          <v-list dense>

            <v-list-item-group v-model="selectedItem">


              <v-list nav class="nav rounded-0 ">


                <v-list-item link class="nav-link" href="#tradingaccount" rel="noopener nofollow">
                  <v-list-item-title class="navlist" @click="toggleMenu = true">Internet trading account
                  </v-list-item-title>
                </v-list-item>

                <v-list-item link class="nav-link" href="#investment" rel="noopener nofollow">
                  <v-list-item-title class="navlist" @click="toggleMenu = true"> Investment advice</v-list-item-title>
                </v-list-item>

                <v-list-item link class="nav-link" href="#rules" rel="noopener nofollow">
                  <v-list-item-title class="navlist" @click="toggleMenu = true"> Rules and regulations</v-list-item-title>
                </v-list-item>
                <v-list-item link class="nav-link" href="#eligibility" rel="noopener nofollow">
                  <v-list-item-title class="navlist" @click="toggleMenu = true"> Eligibility criteria</v-list-item-title>
                </v-list-item>
                <v-list-item link class="nav-link" href="#depository" rel="noopener nofollow">
                  <v-list-item-title class="navlist" @click="toggleMenu = true"> Depository Account</v-list-item-title>
                </v-list-item>
                <v-list-item link class="nav-link" href="#exposure" rel="noopener nofollow">
                  <v-list-item-title class="navlist" @click="toggleMenu = true"> Exposure Limit</v-list-item-title>
                </v-list-item>
                <v-list-item link class="nav-link" href="#disclosure" rel="noopener nofollow">
                  <v-list-item-title class="navlist" @click="toggleMenu = true">Disclosure of information
                  </v-list-item-title>
                </v-list-item>


              </v-list>

            </v-list-item-group>
          </v-list>
        </v-card>
      </v-container>
      <v-layout>
        <v-navigation-drawer floating left v-model="drawer" permanent width="400"
          class="headerd rounded-0 elevation-0 d-none d-md-flex">

          <v-list nav class="nav rounded-0  mt-16 pt-6  ">


            <v-list-item link class="nav-link" href="#tradingaccount" rel="noopener nofollow">
              <v-list-item-title class="navlist">Internet trading account</v-list-item-title>
            </v-list-item>

            <v-list-item link class="nav-link" href="#investment" rel="noopener nofollow">
              <v-list-item-title class="navlist"> Investment advice</v-list-item-title>
            </v-list-item>

            <v-list-item link class="nav-link" href="#rules" rel="noopener nofollow">
              <v-list-item-title class="navlist"> Rules and regulations</v-list-item-title>
            </v-list-item>
            <v-list-item link class="nav-link" href="#eligibility" rel="noopener nofollow">
              <v-list-item-title class="navlist"> Eligibility criteria</v-list-item-title>
            </v-list-item>
            <v-list-item link class="nav-link" href="#depository" rel="noopener nofollow">
              <v-list-item-title class="navlist"> Depository Account</v-list-item-title>
            </v-list-item>
            <v-list-item link class="nav-link" href="#exposure" rel="noopener nofollow">
              <v-list-item-title class="navlist"> Exposure Limit</v-list-item-title>
            </v-list-item>
            <v-list-item link class="nav-link" href="#disclosure" rel="noopener nofollow">
              <v-list-item-title class="navlist">Disclosure of information</v-list-item-title>
            </v-list-item>


          </v-list>

        </v-navigation-drawer>
        <v-container>
          <section class="sce1 section active mt-16" id="tradingaccount">
            <div class="anchor"></div>
            <div class="ml-5 ml-lg-10 ml-ms-10  pt-0 pt-lg-6 pt-md-6 pt-sm-6 ">

              <h4 class="subhead"> Opening your internet trading account</h4>
              <p>To commence internet trading with Zebu, you have to open a trading account and a depository account
                (D-mat)with Zebu. The receipts and payments for buying and selling of shares and the commissions and
                charges will be posted to your trading account. The shares you buy and sell through the trading account
                will be received in or delivered from your D-mat account.</p>

              <p>The trading account refers to your account maintained by Zebu in your books of accounts.</p>

              <p>D-mat account is the account opened by you with the depository for holding securities in an electronic
                form. For the purpose of internet trading through Zebu, the D-mat account refers to the account opened
                by you with Central Depository Services Limited (CDSL) through Zebu Share and Wealth Managements Pvt Ltd
                as depository participants (DP). If you already have a D-mat account with Zebu, then the same account
                can be linked to your internet trading account.</p>
              <p>Provide your account details to us at the time of becoming an internet trading customer. Before your
                account is made operational, you have to fill up and sign all forms/agreements that are part of your
                account opening kit. You also need to furnish the relevant legal documents and financial information,
                which we need to maintain as per the law. You have to assure that the information you provide in your
                account application (or any other information that Zebu may require) is accurate and truthful. You also
                have to authorize Zebu to obtain reports concerning your credit standing and business conduct.</p>
              <p>If your application is approved, Zebu will open an internet trading account (an ‘account’) for you.
                Zebu holds the absolute right to accept or reject your application on any grounds whatsoever and you
                agree that you shall not dispute the result in any manner whatsoever.</p>
            </div>
          </section>

          <section class="sce1 section active mt-16" id="investment">
            <div class="anchor"></div>
            <div class="ml-10 mt-6">
              <h4 class="subhead"> Investment advice</h4>

              <p>While Zebu provides advice regarding the suitability/profitability of a scrip, investment or any other
                tax or legal advice, you assume full responsibility for the transactions in your account and your
                investment decisions. Zebu and its officers, directors, employees, agents, and affiliates cannot be held
                liable with respect to the above.</p>

            </div>
          </section>
          <section class="sce1 section active mt-16" id="rules">
            <div class="anchor"></div>
            <div class="ml-10 mt-6">
              <h4 class="subhead"> Rules and regulations</h4>

              <p>All transactions in your account shall are subject to the constitution, rules, regulations, customs,
                and usage of the exchange or the market and its clearing house, if any, where the transactions are
                executed by Zebu or its agents, including Zebu’s subsidiaries and affiliates. Also, wherever applicable,
                the transactions shall be subject to the provisions of the Securities and Contracts Regulations Act
                (SCRA), as amended, and the rules and regulations of the Securities and Exchange Board of India (SEBI),
                and the Central Depository Services Limited (CDSL), the exchanges where Zebu will facilitate trading,
                and any applicable self-regulatory organisation.</p>

            </div>

          </section>
          <section class="sce1 section active mt-16" id="eligibility">
            <div class="anchor"></div>
            <div class="ml-10 mt-6">
              <h4 class="subhead"> Eligibility criteria</h4>

              <p>You can open accounts if you are over 18 years of age. You must operate these accounts on an individual
                basis. The internet trading account is open for Indian residents only. Indian regulations require us to
                maintain the basic financial details of each client. You need to provide us the details of your bank
                account. We also need your signature on the account opening form, as maintained in the bank records. All
                resident individuals must also provide the income tax returns details (IT PAN/GIR number), at the time
                of account opening.</p>

              <p>
                <strong>Commissions and fees</strong>
                <br>

                Commissions and fees mutually agreed and subject to the maximum prescribed by SEBI.
              </p>
            </div>
          </section>
          <section class="sce1 section active mt-16" id="depository">
            <div class="anchor"></div>
            <div class="ml-10 mt-6">
              <h4 class="subhead"> Depository Account</h4>

              <p><strong>Joint accounts</strong>
                <br>
                Your D-mat account can be held jointly with other people (typically relatives). For this, instructions
                and sufficient conditions to effect a transaction must be given to us by someone who knows your
                password. Please note that regulations require both the account holders to sign on the instructions,
                stating that you are agreeable to any transaction on the account, executed in the manner outlined.
              </p>

              <p><strong>Nomination</strong>
                <br>
                You can also nominate someone for your D-mat account. He or she becomes the beneficiary of the shares
                held in the account in the unfortunate event of your demise.
              </p>
              <p><strong>Cheque receipt/ payment</strong>
                <br>
                You can deposit amount in your Account by using Internet banking, Payment Gateway or by personal cheque
                or a demand draft. Credit will be available in your Account after the funds are cleared.
              </p>
              <p><strong>Digital contract notes</strong>
                <br>

                Contract notes, as per the prevailing rules and regulations, shall be issued within the stipulated time
                limit by email to the address provided by you.
              </p>
            </div>
          </section>
          <section class="sce1 section active mt-16" id="exposure">
            <div class="anchor"></div>
            <div class="ml-10 mt-6">
              <h4 class="subhead"> Exposure Limit</h4>
              <p>Compliance action includes but is not limited to:</p>
              <ul>
                <p>
                <ul>
                  <li>Squaring off of outstanding transactions</li>
                  <li>We have a full lien on cash and securities balances in the trading account /depository account of
                    the client and may opt for the sale of such securities lying or stock lying in the client depository
                    account with us. Any loss arising out of squaring off or sale of securities will be to the client
                    account and we will not be liable for any cost, damage or any consequences.</li>
                  <li>Locking / suspending client account and no further transactions will be allowed till the time all
                    dues are settled or till the time deemed fit by us.</li>
                  <li>Withholding payout of securities bought by the client against any dues receivable by us.</li>
                  <li>Levy of interest @18 p.a. for any delay in receipt of our dues, which include payment in amount,
                    margin amount, fees, commission, or other charges.</li>
                </ul>
                </p>
              </ul>
              <p>We may take any one or all compliance actions mentioned above. We will intimate the client about the
                compliance action being taken and the details regarding the same.</p>
              <p>To avoid compliance action, the client should deposit additional funds, squared off outstanding
                transactions or sale other securities lying in the depository account to make the ledger positive. The
                client should always look at the exposure, market-to-market losses, pay in and other obligation alerts
                forwarded by us, to enable himself to take necessary action in a timely manner.</p>
            </div>
          </section>
          <section class="sce1 section active mt-16" id="disclosure">
            <div class="anchor"></div>
            <div class="ml-10 mt-6">
              <h4 class="subhead"> Disclosure of information regarding client</h4>
              <p>Zebu hereby undertakes to maintain the details of the client as mentioned in the client registration
                form or any other information pertaining to the client, in confidence. We shall not disclose the same to
                any person/entity except when required by the law.</p>
              <p>However, Zebu shall be allowed to share the details of the client as mentioned in the client
                registration form or any other information pertaining to the client with parties/entities with the
                express permission of the client.</p>
              <p>The following advice is issued in the interest of investors:<br>
                Get all information related to your transactions directly from the stock exchanges on your mobile
                phone/email id, at the end of every day. Please intimate us in the case of any change in your address,
                bank account, email, mobile number etc.</p>
              <p>You do not have to issue a cheque while subscribing to an IPO. Write your bank account number clearly
                on the IPO application and sign it, sanctioning your bank to make payments when there is an allotment.
                Your funds will remain in your bank account in the case of non-allotment.</p>

            </div>
          </section>
        </v-container>
      </v-layout>
    </div>

  </div>
</template>

<style>
.headerd {
  position: sticky;
  width: 100%;
  height: 100%;

}

.nav-link {
  padding: 0 20px;
  border-left: 2px;
}

.nav-link.active {
  border-left: 5px solid #377dff;
  color: #377dff !important;
}

.section {
  width: 100%;
  display: absolute;
  flex-direction: column;

}

.sce1 {
  color: #677788;
}

.navlist {
  font-size: 16px;
}

.subhead {
  color: black;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
  
<script>

export default {
  data() {
    return {
      toggleMenu: true,
      drawer: false,
      selectedItem: true,
    };
  }
}
const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

let activeLink = null;

window.addEventListener("scroll", () => {
  $$(".section").forEach(section => {
    const { y } = section.getBoundingClientRect();

    if (y <= 0) {

      if (activeLink != null) {
        activeLink.classList.remove("active");
      }

      activeLink = $(`.nav-link[href~="#${section.id}"]`);
      activeLink.classList.add("active");
    }
  });
}); 
</script>
