<template>
  <div class="signupbgclr">
    <v-container fill-height class="SsoView">
      <v-card max-width="400px" class="mx-auto my-auto elevation-0 py-8 pb-1 rounded-lg text-center">
        <div class="px-4 px-sm-6 px-md-8">
          <img  src="@/assets/logo.svg" width="30%">
          <v-card-subtitle class="body-1">Track all of your data from anywhere, at any time.
          </v-card-subtitle>
          <img src="@/assets/Group 277.svg" class="mt-2 mb-6" width="100%" >
          <v-card-action>
            <!-- <v-btn to="/invite-my-contacts" plain :ripple="false" block large color="white" class="btn mb-2 rounded-lg"> -->
              <v-btn @click="signin" plain :ripple="false" block large color="white" class="btn mb-2 rounded-lg">
              <b style="color:black;">
                sign in with ZEBULL
              </b>
            </v-btn>

            <v-row no-gutters class="my-4">
              <v-col cols="5">
                <v-divider class="mx-2 mt-2" color="#DBE3EA"></v-divider>
              </v-col>
              <v-col cols="2">
                <p class="text-center caption mb-0"> or </p>
              </v-col>
              <v-col cols="5">
                <v-divider class="mx-2 mt-2" color="#DBE3EA"></v-divider>
              </v-col>
            </v-row>

            <v-btn href="https://oa.mynt.in" target="_blank" plain :ripple="false" block large color="white"
              class="btn mb-2 rounded-lg"><b style="color:black;">
                Open an account
              </b></v-btn>
          </v-card-action>

          <v-card-subtitle style="font-size:11px;" class="text-center">
            <p style="color:#858B95;">By continuing with ZEBU, you agree our Terms and Privacy Policy
            </p>
          </v-card-subtitle>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>

export default {
  data: () => ({}),
  methods: {
    signin() {
      console.log("pressed");
      location.href = 'https://zebull.in/?mode=SSO&vendor=MyZebu&redirectUrl=https://ref.zebull.in/invite-my-contacts';
    }
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 0;
}
.btn {
  box-shadow: 0px 8px 16px 2px rgba(22, 34, 51, 0.08) !important;
}
.signupbgclr {
  background-color: #ECEEF0 !important;
  height: 100vh !important;
  overflow-y: hidden !important;
}
.SsoView {
  height: 100vh !important;
  overflow-y: scroll !important;
}
@media only screen and (max-width: 600px) {
  .signupbgclr {
    background-color: white !important;
  }
  .btn:hover {
    background-color: #ECEEF0 !important;
  }
}
</style>