import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsandConditions from '../views/TermsandConditions.vue'
import TestDrive from '../views/testDrive.vue'
import SignUp from '../views/SignUp.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Signup',
    name: 'Signup',
    component: SignUp
  },
  {
    path: '/',
    name: 'homeview',
    component: HomePage
  },
  {
    path: '/invite-my-contacts',
    name: 'testDrive',
    component: TestDrive
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/termsandconditions',
    name: 'termsandconditions',
    component: TermsandConditions
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
